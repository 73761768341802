import React from "react"
import {Link} from "gatsby";

import {getCurrentLocale, getTranslatedValue} from "../helpers/fields";
const slugs = require('../helpers/slugs');

const Menu = ({translations, page}) => (
    <div>
        <input type="checkbox" id="mobile-menu-checkbox"/>

        <nav role="navigation" className="mobile-menu">
            <ul>
                <li className="menu-kunstwerken">
                    <object>
                        <svg height="22" viewBox="0 0 16 22" width="16" xmlns="http://www.w3.org/2000/svg">
                            <g>
                                <path
                                    d="m14.30132 2.54298-1.34376-2.40922-1.74416 3.27536-1.26368.35442v6.64114l5.79018 4.57226v-12.83744zm-1.33408-1.40602.85844 1.53956-1.97252.5533zm1.69686 2.42198v2.75638l-1.50172 2.39712-1.00232-3.5497-1.13432 2.31814v-2.90158zm-3.63836 6.32434v-1.30944l1.01332-2.07174.95546 3.38294 1.66958-2.6653v5.5363z"/>
                                <g transform="translate(0 4.4)">
                                    <path
                                        d="m6.6396 2.93326c.78628-.15752 1.29778-.93148 1.13894-1.72678-.15994-.79662-.92928-1.31186-1.716-1.15434-.78826.15796-1.29734.92884-1.13784 1.7259.15884.79464.92686 1.31274 1.7149 1.15522"/>
                                    <path
                                        d="m.22792 5.78204 3.17702-3.15568c.20482-.21846.45276-.32824.74536-.32824.30668 0 .8173.22418.96778.69234l.69982 2.50228.3993-1.30284c.12606-.37686.53306-.5808.91146-.45474.37708.12628.58058.53438.45474.91146l-1.05138 3.14534c-.09834.2937-.37356.49192-.6831.49192-.00088 0-.00198 0-.00286 0-.31086-.00132-.58586-.20218-.682-.49742l-.64262-1.97538-2.10276 2.11882v7.42852c0 .33616-.11704.61732-.35068.84458-.23364.22572-.51788.33902-.85426.33902-.32164 0-.59928-.1133-.8327-.33902-.23342-.22726-.35024-.50842-.35024-.84458v-9.09392c.00022-.1903.06556-.35068.19712-.48246z"/>
                                </g>
                                <path
                                    d="m13.64968 21.1849h-1.18338v-7.09456c0-.1749-.12562-.32472-.2981-.35464-.1716-.02838-.34122.06842-.4004.23298-.23276.64394-.79354 1.74966-1.31142 1.9745-.10538.0462-.19426.0484-.28864.0077-.64328-.27544-1.19746-2.24818-1.38578-3.56092-.02684-.18722-.19492-.32208-.3817-.308-.18854.01342-.33462.17028-.33462.35926v6.37384h-1.11826c-.1991 0-.36014.16126-.36014.36014s.16104.35992.36014.35992h2.95702c.19888 0 .35992-.16104.35992-.35992s-.16082-.36014-.35992-.36014h-1.1187v-3.58402c.27258.6358.63228 1.18162 1.09868 1.3816.27654.11858.57376.11506.8602-.00946.39028-.17006.7304-.55814 1.00188-.97438v5.55588h-1.18316c-.19888 0-.35992.16126-.35992.36036 0 .19866.16082.35992.35992.35992h3.08682c.19866 0 .35992-.16104.35992-.35992-.00044-.19888-.1617-.36014-.36036-.36014z"/>
                            </g>
                        </svg>
                    </object>
                    <Link to={slugs.getThemesSlug(getCurrentLocale(page))}>{getTranslatedValue(page, translations.works_of_art)}</Link>
                </li>
                <li>
                    <object>
                        <svg height="21" viewBox="0 0 22 21" width="22" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="m.04835165.20543478v20.63478262h4.27912088v-1.4836957h-2.70769231v-10.84239127h3.84395604v1.89456527h1.57142858v-5.13586961h-1.57142858v1.75760869h-3.84395604v-5.34130435h9.35604398v6.825h7.3494505v-1.48369565h-5.778022v-5.34130435h7.7362638v11.80108697h-5.221978v1.4836956h5.221978v4.3826087h-11.19340661v1.4836957h12.76483521v-20.63478262z"/>
                        </svg>
                    </object>
                    <Link to={slugs.getMapSlug(getCurrentLocale(page))}>{getTranslatedValue(page, translations.map)}</Link>
                </li>
                {/*<li>*/}
                {/*    <object>*/}
                {/*        <svg height="21" viewBox="0 0 22 21" width="22" xmlns="http://www.w3.org/2000/svg">*/}
                {/*            <path*/}
                {/*                d="m17.2917855 21c-.2093196 0-.4325764-.0707221-.6634592-.2098002l-4.9992162-3.0139716c-.3131906-.1890305-.9450941-.1890305-1.2582847 0l-4.9994792 3.0142345c-.5148843.309968-1.02266857.2605414-1.29930712-.0885998-.13095627-.1645801-.26243847-.4653463-.14673413-.9619786l1.32218501-5.6853748c.08730418-.3743803-.09808572-.9446267-.38866139-1.1962292l-4.41254267-3.8221493c-.53381774-.46271721-.46413217-.90440182-.40943558-1.07292553.0546966-.16852371.25796808-.56656568.96166083-.6270344l5.81703554-.49926136c.38313914-.03286344.86830846-.3854224 1.01793521-.73955882l2.2725384-5.37645851c.2747978-.65069608.7168409-.72089238.893816-.72089238.176975 0 .6190182.0701963.893816.72089238l2.2725383 5.37645851c.1496268.35413642.6347961.70669538 1.0179352.73929591l5.8170356.49952427c.7036927.06020581.9072272.45851069.9619238.6270344s.1246451.61020832-.4091726 1.07266263l-4.4128057 3.8224122c-.2905756.2516025-.4759655.8218489-.3889243 1.1962292l1.322448 5.6853748c.1154413.4968952-.0160409.7973985-.1469971.9619786-.1525194.1924483-.3784058.2981371-.635848.2981371zm-6.2919492-4.4242075c.435732 0 .8520046.1064775 1.1722953.2999774l4.8340746 2.9143298-1.2787959-5.497396c-.1738195-.7474461.1443675-1.7265136.7244669-2.2291928l4.2671234-3.69621659-5.6248086-.48296109c-.7647005-.06520107-1.5980347-.67041415-1.8970252-1.37763534l-2.1973305-5.19925885-2.19733058 5.19899594c-.29899052.7072212-1.13232472 1.31243428-1.8970252 1.37789825l-5.62480857.48269818 4.2668604 3.6962166c.58036243.5026792.89854936 1.4820096.72446692 2.2291928l-1.27853292 5.4973959 4.83407461-2.9143297c.32029064-.1929741.73656334-.2997145 1.17229534-.2997145z"/>*/}
                {/*        </svg>*/}
                {/*    </object>*/}
                {/*    <a href="#">Jongeren</a>*/}
                {/*</li>*/}
            </ul>
            <ul>
                <li>
                    <object>
                        <svg height="21" viewBox="0 0 9 21" width="9" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="m8.73036511 18.7337534-.28460446 1.1994676c-.85381339.3474372-1.53584179.6118734-2.04371197.7936851-.50841785.1823763-1.09916836.2730939-1.77225152.2730939-1.03363083 0-1.83760649-.2610484-2.4110142-.7795693-.57340771-.5205911-.86020284-1.1802701-.86020284-1.9805426 0-.309795.02081136-.6284359.06407708-.9530996.04363083-.3250401.11300203-.6911102.20793104-1.1002805l1.06703853-3.8937057c.09492901-.3728457.17561867-.7261174.24024341-1.0601916.06553753-.3320039.09711968-.6374701.09711968-.9124461 0-.49725307-.09985801-.84506669-.29866126-1.04061774-.19880325-.19498642-.57815416-.29398532-1.14133874-.29398532-.27584179 0-.55935091.04554702-.84833672.13362969-.29026369.08845909-.53835699.17390681-.74665314.25333178l.28533469-1.20040869c.69900608-.29342068 1.36716024-.54468214 2.00628803-.75321974.63912779-.20910224 1.24302231-.31374746 1.81423935-.31374746 1.02651116 0 1.81862069.25577852 2.37486815.76733556.55624747.51193346.83427992 1.17556486.83427992 1.99334092 0 .1692015-.01825558.4673275-.05750507.8934368-.03833671.4270504-.10971602.8183407-.21377282 1.1742474l-1.06247464 3.8780842c-.08707911.3114889-.16539554.6675838-.23348885 1.0682847-.07028397.3980659-.10387424.7022146-.10387424.9062351 0 .5149449.11135903.8665227.33462475 1.0534161.22490872.1868933.61229209.2798695 1.16251521.2798695.25831643 0 .55223124-.047241.87882353-.1400289.32586207-.0927879.56336714-.1742832.7105071-.2456151zm.26963489-16.28136626c0 .67567689-.24699797 1.2527313-.74336714 1.72721081-.49509128.4761734-1.09168357.71444831-1.78959432.71444831-.70010142 0-1.29815416-.23827491-1.79890467-.71444831-.49983772-.47466772-.75030426-1.05153392-.75030426-1.72721081 0-.67435941.25046654-1.25235488.75030426-1.73285713.49983773-.47974941 1.09898581-.71953001 1.79890467-.71953001.69772819 0 1.29450304.24034523 1.78959432.71953001.49655172.48050225.74336714 1.05868593.74336714 1.73285713z"/>
                        </svg>
                    </object>
                    <Link to={slugs.getPagesSlug(getCurrentLocale(page))}>{getTranslatedValue(page, translations.information)}</Link>
                </li>
                <li>
                    <object>
                        <svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="m19.59375 0v15.125h-8.25l-5.5 4.125v-4.125h-5.5v-15.125zm-4.8125 3.4375v-1.375h-1.375v1.375h-2.0625v1.375h3.40725c-.0350625.4186875-.1010625.8339375-.2000625 1.243-.14575.6029375-.3623125 1.1873125-.639375 1.7414375-.086625-.103125-.1705-.2083125-.2523125-.3141875-.3705625-.4819375-.6964375-.996875-.9783125-1.5351875-.012375-.02475-.0254375-.0495-.0385-.07425l-1.222375.628375.0405625.0790625.0433125.0825c.45375.848375 1.0030625 1.65275 1.6555 2.36225-.136125.185625-.2798125.36575-.430375.5396875-.2894375.3348125-.6043125.6469375-.9411875.933625-.1464375.1244375-.298375.2426875-.452375.3581875-.0914375.066-.0914375.066-.1835625.130625-.0935.0639375-.0935.0639375-.1876875.1265l.759 1.14675c.485375-.32175.941875-.6813125 1.364-1.082125.3925625-.372625.7541875-.776875 1.0814375-1.20725.3444375.2729375.71225.515625 1.1048125.713625.2124375.1065625.4310625.1973125.6551875.276375l.455125-1.298c-.144375-.0501875-.2853125-.1065625-.4228125-.1725625-.3719375-.17875-.716375-.4118125-1.0346875-.675125.4021875-.716375.7143125-1.4829375.9219375-2.27975.147125-.5658125.23925-1.1419375.2811875-1.7235625h.7143125v-1.375zm-8.9375.6875h-.9068125l-2.946625 6.875h1.496l.2949375-.6875.2949375-.6875h3.535125l.589875 1.375h1.496l-2.946625-6.875zm-1.178375 4.125h2.35675l-1.178375-2.75z"
                                fillRule="evenodd" transform="translate(.03125 .375)"/>
                        </svg>
                    </object>
                    <Link to={slugs.getLanguageSlug()}>{getTranslatedValue(page, translations.language)}</Link>
                </li>
            </ul>
        </nav>

        <label htmlFor="mobile-menu-checkbox" id="mobile-menu-overlay"></label>
    </div>
);

export default Menu
