module.exports = {
   getRecordSlug(record, locale) {
       const localeForSlug = record.fieldValues.slug[locale] ? locale : module.exports.getSlugFallbackLocale();
       const title = record.fieldValues.title[localeForSlug].replace(/\s/g, '-').toLowerCase();
       const creator = record.fieldValues.creator_name.replace(/\s/g, '-').toLowerCase() || 'unknown';
       return '/' + creator + '/' + title + '/' + locale + '/';
   },

   getRoomSlug(room, locale) {
       const localeForSlug = room.fieldValues.slug[locale] ? locale : module.exports.getSlugFallbackLocale();
       return '/room/' + room.fieldValues.slug[localeForSlug] + '/' + locale + '/';
   },

   getRoomInThemeSlug(theme, room, locale) {
     return '/room-in-theme' + theme.id + '/' + room.id + '/' + locale + '/';
   },

   getThemeSlug(theme, locale) {
      const localeForSlug = theme.fieldValues.slug[locale] ? locale : module.exports.getSlugFallbackLocale();
      return '/theme/' + theme.fieldValues.slug[localeForSlug] + '/' + locale + '/';
   },

   getThemesSlug(locale) {
       return '/themes/' + locale + '/';
   },

    getPageSlug(page, locale) {
       const localeForSlug = page.fieldValues.slug[locale] ? locale : module.exports.getSlugFallbackLocale();
       return '/page/' + page.fieldValues.slug[localeForSlug] + '/' + locale + '/';
    },

    getPagesSlug(locale) {
       return '/pages/' + locale + '/';
    },

    getHomepageSlug(locale) {
       return '/' + locale + '/';
    },

    getSearchSlug(locale) {
       return '/search/' + locale + '/';
    },

    getLanguageSlug() {
       return '/';
    },

    getMapSlug(locale) {
      return '/map/' + locale;
    },

    getFloorSlug(floor, locale) {
      return '/map/floor/' + floor + '/' + locale + '/';
    },

    getSlugFallbackLocale() {
      return 'en_GB';
    },
    forceHTTPS() {
       if (typeof window !== 'undefined' && window.location.hostname !== 'localhost') {
           if (window.location.protocol !== 'https:') {
               console.log("ok this");
               window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
           }
       }
    }
};
