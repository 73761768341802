
export const getTranslatedFields = (page, fieldValues) => {

    const fields = fieldValues ? fieldValues : page.props.pageContext.data.fieldValues;

    let translatedFields={};
    Object.keys(fields).forEach(key => {
        const overridekey = 'override_' + key;
        if(getOverrideValue(page, fields[overridekey]) !== null) {
            translatedFields[key] = getOverrideValue(page, fields[overridekey]);
        } else {
            translatedFields[key] = getTranslatedValue(page, fields[key]);
        }
    });

    return translatedFields;
};

export const getTranslatedValue = (page, fieldvalue) => {
    const locale = getCurrentLocale(page);
    if(typeof fieldvalue === 'object' && fieldvalue !== null) {
        if(fieldvalue[locale] === undefined) {
            return fieldvalue[getFallbackLocale()];
        } else {
            return fieldvalue[locale];
        }
    }

    return fieldvalue;
};

export const getOverrideValue = (page, fieldvalue) => {
    if (fieldvalue === undefined) {
        // if override_[field] does not exist
        return null;
    }

    if (typeof fieldvalue !== 'object' || fieldvalue === null) {
        return null;
    }

    if (fieldvalue[getCurrentLocale(page)] === undefined) {
        return null;
    }

    if (fieldvalue[getCurrentLocale(page)] === "") {
        return null;
    }

    return fieldvalue[getCurrentLocale(page)];
};

export const getCurrentLocale = (page) => {
    return page.props.pageContext.locale;
};

export const getFallbackLocale = () => {
    return 'en_GB';
};