import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
const slugs = require('../helpers/slugs');

const Header = ({title, includeMenu, locale, onHomepage, context}) => (
    <header>
        {includeMenu &&
        <label htmlFor="mobile-menu-checkbox" id="mobile-menu-btn">
            <span className="menu-link-large">
                <object>
                    <figure>
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1"
                             width="24" height="24" viewBox="0 0 24 24">
                            <path d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z"/>
                        </svg>
                    </figure>
                </object>
                {onHomepage &&
                    <span>Menu</span>
                }
            </span>
        </label>
        }

        { !includeMenu &&
            <Link to={context.getHistoryLink()} onClick={() => context.popHistory()}>
            <object className="back btn-round">
                <figure>
                    <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <path d="m20 11v2h-12l5.5 5.5-1.42 1.42-7.92-7.92 7.92-7.92 1.42 1.42-5.5 5.5z"/>
                    </svg>
                </figure>
            </object>
            </Link>
        }

        <h1>{title}</h1>
        <object className="search btn-round">
            <Link to={slugs.getSearchSlug(locale)}>
                <figure>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                        <path d="M0 0h24v24H0V0z" fill="none"/>
                        <path
                            d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/>
                    </svg>
                </figure>
            </Link>
        </object>
    </header>
);

Header.propTypes = {
    title: PropTypes.string,
};

Header.defaultProps = {
    title: '',
};

export default Header
