/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { Context } from "../context/provider";

import Header from "./header"
import Menu from "./menu"
import {getTranslatedValue} from "../helpers/fields";
const slugs = require('../helpers/slugs');

const Layout = ({ children, className, title, lang, includeMenu, includeMissingLocaleWarning, translations, page }) => {

  if (includeMenu === undefined) {
      includeMenu = false;
  }

  if (includeMissingLocaleWarning === undefined) {
      includeMissingLocaleWarning = false;
  }

  let onHomepage = false;

  if( page !== undefined && lang !== undefined) {
      onHomepage = page.props.path === slugs.getHomepageSlug(lang);
  }

  const urlParams = typeof window !== 'undefined' ? new URLSearchParams(window.location.search) : null;
  const history = urlParams && urlParams.has('history') ? JSON.parse(urlParams.get('history')).history : [];

  return (
    <Context.Consumer>{context => (
        <>
          {context.addHistory(page.props.path, history)}
          { includeMenu &&
              <Menu translations={translations} page={page}  />
          }
          <Helmet htmlAttributes={{
              lang: lang
          }}>
          </Helmet>
          <div className={className + ' body'}>
            <Header context={context} title={title} includeMenu={includeMenu} locale={lang} onHomepage={onHomepage} />
            <div id='content'>
                { includeMissingLocaleWarning &&
                    <div className="locale-unavailable-warning">{getTranslatedValue(page, translations.missing_locale_message)}</div>
                }
                {children}
            </div>

          </div>
        </>
    )}
    </Context.Consumer>
  )
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout
